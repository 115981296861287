<template>
  <div>
    iFrame for setting local storage
  </div>
</template>

<script>
window.addEventListener("message", (e) => {
  // console.debug("onmessage");
  // console.debug(e);
  if (e.origin !== "https://initialview.com" && e.origin !== "https://test.initialview.com" && e.origin !== "https://lab-sc.initialview.com") {
    // console.warn("unauthorized origin message -- returning")
    return;
  }
  var payload = JSON.parse(e.data);
  localStorage.setItem(payload.key, payload.data);
  // console.debug("onmessage - storage set");
  // console.debug(localStorage.device_token);
}, false);
</script>

<style lang="scss">
</style>
